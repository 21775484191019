.swipe-right-btn {
    /*transform: translate(50px, -75px);*/
    transform: translateX(50px);
}

.swipe-left-btn {
    /*transform: translate(-50px, -75px);*/
    transform: translateX(-50px);

}

.custom-height {
    height: 50px !important;
}

.custom-rotate-90 img,
.custom-rotate-90 {
    transform: rotate(90deg) !important;
}

.custom-rotate-270 img,
.custom-rotate-270 {
    transform: rotate(270deg) !important;
}

.custom-rotate-180 img,
.custom-rotate-180 {
    transform: rotate(180deg) !important;
}



.custom-arrow {
    /* border: solid black; */
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 15px;
}

.custom-arrow-right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.custom-arrow-left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.custom-option-1 {
    width: 200px !important;
    height: 200px !important;
}


/* .square_wrapper_left {
    background: white;
    width: 55%;
    margin-top: 30px;
    font-size: 25px;
    transform: translateX(110px);
}

.square_wrapper_left button {
    background: transparent;
    margin: 10px;
    border: none;
    font-size: 17px;
}

.square_wrapper_right {
    background: white;
    width: 55%;
    margin-top: 30px;
    transform: translateX(110px);
    font-size: 25px;
}


.square_wrapper_right button {
    background: transparent;
    margin: 10px;
    border: none;
    font-size: 17px;
} */




.frontpage {
    /* background: linear-gradient(to right, #d9d9d9 0%, #f9f9f9 6%, #ffffff 8%, #ffffff 100%); */
    background: white;
    overflow: hidden;
    position: relative;
    z-index: 0;
    box-shadow: 0 18px 10px -13px grey;

}

.backpage {
    /* background: linear-gradient(to right, #ffffff 0%, #ffffff 92%, #f9f9f9 94%, #d9d9d9 100%); */
    background: white;
    overflow: hidden;
    position: relative;
    z-index: 0;
    box-shadow: 15px 20px 22px -13px grey;
}


.pageFoldRight {
    width: 550px;
    height: 550px;
    /* border-left-width: 1px;
    border-left-color: #DDDDDD;
    border-left-style: solid;
    border-bottom-width: 3px;
    border-bottom-color: #DDDDDD;
    border-bottom-style: solid; */
    /* box-shadow: 3px 0px 7px 0px #dddddd inset; */
}

.pageFoldLeft {
    width: 550px;
    height: 550px;
    /* border-right-width: 1px;
    border-right-color: #DDDDDD;
    border-right-style: solid;
    border-bottom-width: 3px;
    border-bottom-color: #DDDDDD;
    border-bottom-style: solid; */
    /*box-shadow: -4px 4px 50px -10px #dddddd inset;*/
    /* box-shadow: -26px 5px 8px -21px #dddddd inset; */
    /* z-index: 2; */
}


.album-image-medium {

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 80%;
    height: 80%;
    overflow: hidden;
    object-fit: contain;


}

.album-image-full {
    display: block;
    position: relative;
    z-index: -1;
    /*width: 100% !important;*/
    /*height: 463px;*/
    width: 500px;
    height: 550px;
    object-fit: contain;
    margin: auto;
}


.album-image-small {
    width: 70% !important;
    height: 350px;
    object-fit: contain;
    margin: 0 auto;
    transform: translateY(25%);
}

.album-image-text {
    width: 60% !important;
    height: 300px;
    object-fit: contain;
    margin: 0 auto;
    transform: translateY(25%);
    overflow: hidden;
}



/* Cover Swiper */

.cover-swiper .swiper-pagination {
    position: relative;
    bottom: 0;
    /* width: 500px; */
    padding-top: 45px;
}


.cover-swipe-right-btn {
    transform: translate(-5vw, 5px);

    position: relative;
    z-index: 1;
}

.cover-swipe-left-btn {
    transform: translate(5vw, 5px);

    position: relative;
    z-index: 2;
}

/* Cropper Options */

.medium-image-crop {
    margin: 0 auto;
    transform: translate(0%, 12%);
}
.crop-text{
    margin: 0 auto;
    transform: translate(0%, 10%);
}

.small-image-crop {
    transform: translate(-2%, 35%);
    margin: 0 auto;
}

.shadow-image {

    height: 100%;
    position: absolute;
    display: block;
}