body {
    background-color: #F5F5F5;
}

.footer-links p {
    margin-bottom: 10px;
}

.middle-image-cover {
    position: absolute;
    left: 50%;
    text-align: center;
    height: 550px;
    transform: translate(-50%, -3px);
    user-select: none;
    -webkit-user-drag: none;
    pointer-events: none;

}
