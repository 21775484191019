.drag-n-drop-list:first-child {
  display: none;
}

.drag-n-drop-list:nth-child(2) {
  margin-left: 72px;
}


.drag-n-drop-list:nth-child(even) {
  margin-right: 40px;
}


.drag-n-drop-images {
  width: 72px;
  height: 100%;
  object-fit: contain;
  padding: 5px;
}


.image-icon,
.img-icon {
  padding-top: 0%;
  font-size: 20px;
  width: 70%;
}

.box {
  position: relative;
  display: inline-block;
  /*border-bottom: 1px dotted black;*/
  visibility: visible;
}

.box .tooltiptext {
  visibility: hidden;
  width: 170px;
  background-color: black;
  text-align: center;
  border-radius: 20px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -85px;
  opacity: 0;
  transition: opacity 0.5s;
  display: flex;
}

.box .tooltiptext .left-tooltip,
.box .tooltiptext .right-tooltip {
  width: 50%;
}

.box .tooltiptext .right-tooltip {
  /* text-align: left;
  padding-left: 15px; */

  border-left: grey;
}

.box .tooltiptext .left-tooltip {
  /* text-align: right;
  padding-right: 15px; */
  border-right: grey 1px solid;

}

.box .tooltiptext img {
  width: 25px;
  height: 25px;
  margin: 0 auto;
}



.box .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.box:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.box .tooltiptext .text {
  color: white;
  font-size: 10px;
}

/*Cover Tooltiptext*/

.box .tooltiptext-cover {
  width: 100px;
}

.box .tooltiptext-cover .left {
  width: 100%;
  border-left: unset;
  border-right: unset;

}

.box .tooltiptext-cover .left {
  text-align: center;
}




.box {
  width: 72px;
  height: 72px;
  /*border: solid 1px #ccc;*/
  font-size: 30pt;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #fff;
  position: relative;
  z-index: 1;
  /* transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
  box-shadow: 0 2px 16px -1px rgb(0 0 0 / 5%), 0 4px 11px 0 rgb(0 0 0 / 17%), 0 1px 21px 0 rgb(0 0 0 / 0%); */
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}


.left-box {
  box-shadow: inset -14px 0 14px -13px rgba(0, 0, 0, .50);
}

.right-box {
  box-shadow: inset 13px 0 14px -14px rgba(0, 0, 0, .50);
}

.box:active {
  /* box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12); */
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  opacity: 0.6;
}

.box-text {
  color: #7D7D82;
  font-weight: 700;
  font-size: 13px;
  text-align: center;
  font-family: 'Urbanist', sans-serif;

}

/* Drag and drop list */


@media screen and (min-width:1300px) and (max-width:1345px) {
  .album-images-list {
    width: 60%;
  }
}

@media screen and (min-width:1346px) and (max-width:1400px) {
  .album-images-list {
    width: 70%;
  }
}

@media screen and (min-width:1401px) and (max-width:1469px) {
  .album-images-list {
    width: 55%;
  }
}

@media screen and (min-width:1500px) and (max-width:1586px) {
  .album-images-list {
    width: 50%;
  }
}

@media screen and (min-width:1587px) and (max-width:1675px) {
  .album-images-list {
    width: 58%;
  }
}


@media screen and (min-width:1800px) and (max-width:1900px) {
  .album-images-list {
    width: 52%;
  }
}

@media screen and (min-width:1900px) and (max-width:2000px) {
  .album-images-list {
    width: 50%;
  }
}

@media screen and (min-width:2000px) and (max-width:2100px) {
  .album-images-list {
    width: 48%;
  }
}

@media screen and (min-width:2100px) and (max-width:2200px) {
  .album-images-list {
    width: 45%;
  }
}

@media screen and (min-width:2200px) and (max-width:2450px) {
  .album-images-list {
    width: 48%;
  }
}

@media screen and (min-width:2560px) {
  .album-images-list {
    width: 60%;
  }
}
