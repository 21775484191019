.swiper {
    width: 100%;
    height: 100%;
}

.home-slider {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.home-slider img {
    display: block;
    width: 100%;
    height: 90vh;
    object-fit: cover;
}

/* 

Remove this later

.swiper-button-prev,
.swiper-button-next {
    color: black ;
    background-color: white;
    border-radius: 100px;
    display: none;
} */

/* .swiper-button-next::after, .swiper-button-prev::after{
    font-size: 15px;
} */

.swiper-pagination-bullet-active {
    background-color: #ED4A43 !important;
}

.swiper-pagination-bullet {
    background-color: #D9D9D9;
    opacity: 1;

}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: 35px;
}