@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

* {
    font-family: "Urbanist", sans-serif;
    scroll-behavior: smooth;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.modal,
.overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 100;
}

.overlay {
    background: #000000;
    opacity: 0.6;
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    /* background: #ffffff; */
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 100%;
    min-width: 300px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    z-index: 101;
}

.data-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0.9);
    transform: translate(-50%, -50%) scale(0.9);
    overflow-y: auto;
    -webkit-box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.4);
    background-color: white;
    -webkit-transition: all 0.3s;
    padding: 50px;
    transition: all 0.3s;
    z-index: 101;
    min-width: 50%;
    height: 90%;
    min-height: 90%;
}

.modal-content img {
    margin: 0 auto;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}

/* Custom Fonts */

@font-face {
    font-family: "Lato";
    src: url('/src/assets/custom-fonts/LATO-REGULAR.TTF') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Merriweather';
    src: url('/src/assets/custom-fonts/MERRIWEATHER-REGULAR.TTF') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/custom-fonts/MONTSERRAT-REGULAR.TTF') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Opensans';
    src: url('/src/assets/custom-fonts/OPENSANS-REGULAR.TTF') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Playfair';
    src: url('/src/assets/custom-fonts/PLAYFAIRDISPLAY-VARIABLEFONT_WGHT.TTF') format('truetype');
}

@font-face {
    font-family: 'Raleway';
    src: url('/src/assets/custom-fonts/RALEWAY-VARIABLEFONT_WGHT.TTF') format('truetype');
}

@font-face {
    font-family: 'Urbanist';
    src: url('/src/assets/custom-fonts/URBANIST-VARIABLEFONT_WGHT.TTF') format('truetype');
}

.Lato {
    font-family: 'Lato', sans-serif;
}

.Merriweather {
    font-family: 'Merriweather', sans-serif;
}

.Montserrat {
    font-family: 'Montserrat', sans-serif;
}

.Opensans {
    font-family: 'Opensans', sans-serif;
}

.Playfair {
    font-family: 'Playfair', sans-serif;
}

.Raleway {
    font-family: 'Raleway', sans-serif;
}

.Urbanist {
    font-family: 'Urbanist', sans-serif;
}